import React, { useState } from 'react';
import { correctText, getHighlightedText } from './textCorrectorService';

const TextCorrector = () => {
  const [inputText, setInputText] = useState('');
  const [correctedText, setCorrectedText] = useState('');
  const [correctionSummary, setCorrectionSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [language, setLanguage] = useState('en'); // State to manage language selection

  const texts = {
    en: {
      title: 'Text Corrector',
      placeholder: 'Paste your paragraph here...',
      submitButton: 'Submit',
      correctingButton: 'Correcting...',
      originalParagraph: 'Original Paragraph',
      correctedParagraph: 'Corrected Paragraph',
      copyButton: 'Copy Corrected Text',
      copySuccess: 'Corrected text copied to clipboard!',
      summaryOfChanges: 'Summary of Changes',
      languageSwitch: 'Switch to German',
      flag: '🇩🇪', // German flag emoji
    },
    de: {
      title: 'Textkorrektur',
      placeholder: 'Fügen Sie Ihren Absatz hier ein...',
      submitButton: 'Einreichen',
      correctingButton: 'Korrigieren...',
      originalParagraph: 'Originalabsatz',
      correctedParagraph: 'Korrigierter Absatz',
      copyButton: 'Korrigierten Text kopieren',
      copySuccess: 'Korrigierter Text in die Zwischenablage kopiert!',
      summaryOfChanges: 'Zusammenfassung der Änderungen',
      languageSwitch: 'Wechseln Sie zu Englisch',
      flag: '🇬🇧', // British flag emoji
    },
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    setCorrectedText('');
    setCorrectionSummary('');
    setCopySuccess('');
  };

  const handleSubmit = async () => {
    setLoading(true);
    setCorrectedText('');
    setCorrectionSummary('');
    setCopySuccess('');
    try {
      const { corrected, summary } = await correctText(inputText, language);
      setCorrectedText(corrected);
      setCorrectionSummary(summary);
    } catch (error) {
      console.error('Error correcting text:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(correctedText).then(() => {
      setCopySuccess(texts[language].copySuccess);
    }).catch((error) => {
      console.error('Failed to copy text:', error);
    });
  };

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'de' : 'en');
  };

  return (
    <div className="container">
      <div className="language-switch" style={{ textAlign: 'right', marginBottom: '10px' }}>
        <button onClick={toggleLanguage}>
          <span role="img" aria-label="flag">{texts[language].flag}</span> {texts[language].languageSwitch}
        </button>
      </div>
      <div className="input-section">
        <h1>{texts[language].title}</h1>
        <textarea
          value={inputText}
          onChange={handleInputChange}
          placeholder={texts[language].placeholder}
          rows="10"
          cols="50"
        />
        <br />
        <button onClick={handleSubmit} disabled={loading}>
          {loading ? texts[language].correctingButton : texts[language].submitButton}
        </button>
      </div>
      <div className="output-section">
        <h2>{texts[language].originalParagraph}</h2>
        <p>{inputText}</p>
        <h2>{texts[language].correctedParagraph}</h2>
        <p>{correctedText ? getHighlightedText(inputText, correctedText) : ''}</p>
        <button onClick={handleCopy} disabled={!correctedText}>
          {texts[language].copyButton}
        </button>
        {copySuccess && (
          <div style={{ color: 'green', marginTop: '10px' }}>
            <span>&#10003;</span> {copySuccess}
          </div>
        )}
        <h2>{texts[language].summaryOfChanges}</h2>
        <p>{correctionSummary}</p>
      </div>
    </div>
  );
};

export default TextCorrector;
