// src/textCorrectorService.js
import axios from 'axios';
import DiffMatchPatch from 'diff-match-patch';
import config from './config';

const dmp = new DiffMatchPatch();

export const correctText = async (inputText, language) => {
  const prompt = language === 'en'
    ? `Correct the following paragraph and explain the changes you made. The input language could be any language, so make sure to provide the corrected text and explanation in the same language as the input.\n\n${inputText}\n\nPlease provide the response in the following format:\n\nCorrected text:\n[Corrected text here]\n\nSummary of corrections:\n[Summary of corrections here]`
    : `Korrigieren Sie den folgenden Absatz und erklären Sie die vorgenommenen Änderungen. Die Eingabesprache kann jede Sprache sein, stellen Sie also sicher, dass der korrigierte Text und die Erklärung in derselben Sprache wie die Eingabe vorliegen.\n\n${inputText}\n\nBitte geben Sie die Antwort im folgenden Format an:\n\nKorrigierter Text:\n[Korrigierter Text hier]\n\nZusammenfassung der Änderungen:\n[Zusammenfassung der Änderungen hier]`;

  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', {
      model: 'gpt-4o',
      messages: [
        {
          role: 'system',
          content: 'You are a text correction assistant. Provide corrected text and a summary of changes in the same language as the input text.',
        },
        {
          role: 'user',
          content: prompt,
        },
      ],
      max_tokens: 1500,
    }, {
      headers: {
        'Authorization': `Bearer ${config.API_KEY}`,
        'Content-Type': 'application/json',
      },
    });

    const responseText = response.data.choices[0].message.content.trim();
    
    let corrected = '';
    let summary = '';
    const correctedIndex = responseText.indexOf(language === 'en' ? 'Corrected text:' : 'Korrigierter Text:');
    const summaryIndex = responseText.indexOf(language === 'en' ? 'Summary of corrections:' : 'Zusammenfassung der Änderungen:');

    if (correctedIndex !== -1 && summaryIndex !== -1) {
      corrected = responseText.substring(correctedIndex + (language === 'en' ? 'Corrected text:' : 'Korrigierter Text:').length, summaryIndex).trim();
      summary = responseText.substring(summaryIndex + (language === 'en' ? 'Summary of corrections:' : 'Zusammenfassung der Änderungen:').length).trim();
    } else {
      // Handle cases where the format is unexpected
      const splitResponse = responseText.split('\n\n');
      if (splitResponse.length > 1) {
        summary = splitResponse[0].trim();
        corrected = splitResponse.slice(1).join('\n\n').trim();
      } else {
        corrected = responseText;
      }
    }

    return {
      corrected,
      summary,
    };
  } catch (error) {
    console.error('Error correcting text:', error);
    throw error;
  }
};

export const getHighlightedText = (inputText, correctedText) => {
  if (!correctedText) return null;
  const diffs = dmp.diff_main(inputText, correctedText);
  dmp.diff_cleanupSemantic(diffs);

  let result = [];
  let currentText = '';

  diffs.forEach((part, index) => {
    if (part[0] === 0) {
      if (currentText) {
        result.push(<span key={index} style={{ color: 'green', fontWeight: 'bold' }}>{currentText}</span>);
        currentText = '';
      }
      result.push(<span key={index}>{part[1]}</span>);
    } else if (part[0] === 1) {
      currentText += part[1];
    } else if (part[0] === -1) {
      // Do nothing for deletions
    }
  });

  if (currentText) {
    result.push(<span key={result.length} style={{ color: 'green', fontWeight: 'bold' }}>{currentText}</span>);
  }

  return result;
};
